import styled from "styled-components"
import React from 'react';

const PoqqyCheckbox = styled.div`
    display: flex; 
    position: relative;
    
    & label {
     background-color: #fff;
     border: 1px solid ${props => props.theme.colors.grey90};
     border-radius: 50%;
     cursor: pointer;
     height: 15px;
     left: 0;
     position: absolute;
     top: 0;
     width: 15px;
    }
    
    & label:after {
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      content: "";
      height: 3px;
      left: 3px;
      opacity: 0;
      position: absolute;
      top: 4px;
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      width: 7px;
    }
    
    & input[type="checkbox"] {
      visibility: hidden;
    }
    
    & input[type="checkbox"]:checked + label {
      background-color: ${props => props.theme.colors.sulu45};
      border-color: ${props => props.theme.colors.sulu45};
    }
    
    & input[type="checkbox"]:checked + label:after {
      opacity: 1;
    }
`;

const Checkbox = (props) => {
    return (
        <PoqqyCheckbox onClick={props.onClick}>
            <input type="checkbox" id={props.id} readOnly={props.readOnly} checked={props.checked}/>
            <label htmlFor="checkbox"/>
        </PoqqyCheckbox>
    )
};

export default Checkbox;

import axios from 'axios';

const optimizerApiUrl = 'https://optimizer-api.poqqy.com/api/v0.1';
export const poqqyApiUrl = "https://api.poqqy.com";
export const optimizerApi = axios.create({baseURL: optimizerApiUrl});
export const coreApi = axios.create({baseURL: poqqyApiUrl})

export const sendPost = (url, data) => {
  return coreApi.post(url, data);
}

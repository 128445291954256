import React from "react"
import { Link } from "gatsby"
import ReactGA from "react-ga"
import styled from "styled-components"
import poqqyLogoSrc from "../../images/poqqy-logo-white.svg"
import media from "../mediaQuery"

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  padding: ${props => props.theme.margins.size05} ${props => props.theme.margins.size07};
`

const NavigationLinksContainer = styled.div`
  display: flex;
  
  ${media.tablet`
    display: none;
  `};
`

const NavigationLink = styled(Link)`
  font-size: ${props => props.theme.fontSizes.size04};
  color: ${props => props.theme.colors.white};
  font-weight: 500;
  text-decoration: none;
  
  &:hover {
    opacity: 0.8;
  }
`

const ExternalNavigationLink = styled.div`
  font-size: ${props => props.theme.fontSizes.size04};
  color: ${props => props.theme.colors.white};
  font-weight: 500;
  text-decoration: none;
  margin-right: ${props => !props.isLast && props.theme.margins.size08};
  cursor: pointer;
  
  &:hover {
    opacity: 0.8;
  }
`

const Header = () => {
    const navigateToOptimizer = () => {
        ReactGA.event({
            category: "Optimizer",
            action: "User clicked link to optimizer",
        })
        window.open("https://optimizer.poqqy.com", "_self")
    }

    return (
        <HeaderContainer>
            <Link
                to="/"
                style={{
                    color: `white`,
                    textDecoration: `none`,
                }}>
                <img height={30} src={poqqyLogoSrc} alt='poqqy-logo'/>
            </Link>

            <NavigationLinksContainer>
                <ExternalNavigationLink onClick={navigateToOptimizer}>Optimizer</ExternalNavigationLink>
                <NavigationLink to='certificaten'>CO2 certificaten</NavigationLink>
            </NavigationLinksContainer>
        </HeaderContainer>
    )
}

export default Header;

export const theme = {
    colors: {
        sulu: '#AEEA72',
        sulu45: '#73c81e',
        sulu50: '#a6e864',
        sulu95: '#f5fced',
        sulu99: '#fafef6',
        emerald: '#55CC99',
        downy: '#61CBBB',
        poloBlue: '#7f9dc7',
        wildWaterLemon: '#FF6384',
        wildWaterLemon98: '#fff5f7',
        flax: '#d8d841',
        grey20: '#333',
        grey40: '#666',
        grey50: '#808080',
        grey65: '#a6a6a6',
        grey80: '#ccc',
        grey85: '#d9d9d9',
        grey90: '#e6e6e6',
        grey95: '#f2f2f2',
        grey98: '#f7f7f7',
        grey99: '#fcfcfc',
        white: '#ffffff'
    },
    margins: {
        size01: '4px',
        size02: '8px',
        size03: '12px',
        size04: '16px',
        size05: '24px',
        size06: '32px',
        size07: '48px',
        size08: '64px',
        size09: '96px',
        size10: '128px',
        size11: '152px',
        size12: '192px',
        size13: '256px'
    },
    fontSizes: {
        size01: '12px',
        size02: '14px',
        size03: '16px',
        size04: '18px',
        size05: '20px',
        size06: '24px',
        size07: '30px',
        size08: '36px',
        size09: '48px',
        size10: '60px',
        size11: '72px',
    },
    sideBar: {
        width: '475px'
    }
};

import { css } from "styled-components"
import Responsive from 'react-responsive';
import React from 'react';

const sizes = {
  desktop: 992,
  tablet: 768,
  phone: 576,
}

export const Desktop = props => <Responsive {...props} minWidth={sizes.desktop}/>;
export const TabletAndDesktop = props => <Responsive {...props} minWidth={sizes.tablet}/>;
export const TabletAndMobile = props => <Responsive {...props} maxWidth={sizes.desktop}/>;
export const Tablet = props => <Responsive {...props} minWidth={sizes.tablet} maxWidth={sizes.desktop}/>;
export const Mobile = props => <Responsive {...props} maxWidth={sizes.phone}/>;

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `

  return acc
}, {});

export default media;

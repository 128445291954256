/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import React from "react"
import PropTypes from "prop-types"
import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Avenir Next';
    src: 
       url('/fonts/AvenirNext-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/AvenirNext-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('/fonts/AvenirNext-Regular.ttf') format('truetype') /* Safari, Android, iOS */
  }
  
  @font-face {
    font-family: 'Avenir Next';
    font-weight: bold;
    src: 
       url('/fonts/AvenirNext-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/AvenirNext-Bold.woff') format('woff'), /* Pretty Modern Browsers */
       url('/fonts/AvenirNext-Bold.ttf') format('truetype') /* Safari, Android, iOS */
  }
  
  @font-face {
    font-family: 'Avenir Next';
    font-weight: 500;
    src: 
       url('/fonts/AvenirNext-Medium.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/AvenirNext-Medium.woff') format('woff'), /* Pretty Modern Browsers */
       url('/fonts/AvenirNext-Medium.ttf') format('truetype') /* Safari, Android, iOS */
  }

  body {
    margin: 0;
    padding: 0;
    font-weight: 300;
    font-family: 'Avenir Next', 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`

const Layout = ({ header, children }) => (
    <>
        <GlobalStyle/>
        {header}
        {children}
    </>
)

Layout.propTypes = {
    header: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
}

export default Layout

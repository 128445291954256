import styled from "styled-components"
import Input from "./input"

const ButtonInput = styled(Input)`
    border-radius: ${props => props.theme.margins.size04} 0 0 ${props => props.theme.margins.size04};
    border: none;
    outline: none;
`

export default ButtonInput

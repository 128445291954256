import styled from "styled-components"

const Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: ${props => props.borderRadius || "16px"};
    color: ${props => props.theme.colors.white};
    font-weight: bold;
    font-size: ${props => props.theme.fontSizes.size02};
    background-color: ${props => props.theme.colors.wildWaterLemon};
    padding: ${props => props.theme.margins.size02} ${props => props.theme.margins.size05};
    cursor: pointer;
  
    &:disabled {
        cursor: not-allowed;
        background-image: none;
        background-color: ${props => props.theme.colors.grey90}
    }
  
    &:hover {
      opacity: 0.8;
    }
`

export default Button

import styled from "styled-components"
import { Link } from "gatsby"
import React, { PureComponent } from "react"
import ButtonInput from "../../shared/components/inputs/buttonInput"
import media from "../../shared/mediaQuery"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index"
import { faCheck, faEnvelope, faTimes } from "@fortawesome/free-solid-svg-icons/index"
import { faFacebook, faInstagram, faLinkedin } from "@fortawesome/fontawesome-free-brands/index"
import ReactGA from "react-ga"
import Checkbox from "../../shared/components/checkbox"
import { poqqyApiUrl, sendPost } from "../../shared/api"
import InputButton from "../../shared/components/buttons/inputButton"

const PoqqyFooter = styled.footer`
  box-sizing: border-box;
  background-color: ${props => props.theme.colors.grey20};
`

const FooterContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  max-width: 960px;
  box-sizing: border-box;
  padding: ${props => props.theme.margins.size07} 0;
  
  ${media.desktop`
    flex-direction: column;
    padding: ${props => props.theme.margins.size06} ${props => props.theme.margins.size05};
  `}
`

const Section = styled.div`
  flex: ${props => props.flex};
  display: flex;
  flex-direction: column;
  align-items: center;
  
  ${media.desktop`
    margin-bottom: ${props => !props.isLast && props.theme.margins.size07};
    align-items: flex-start;
  `};
`

const SectionHeader = styled.div`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.size02};
  text-transform: uppercase;
  font-weight: 800;
  margin-bottom: ${props => props.theme.margins.size02};
  letter-spacing: 1px;
`

const SectionInfoContainer = styled.footer`
  display: flex;
  flex-direction: column;
`

const SocialMediaContainer = styled.footer`
  display: flex;
  flex-direction: row;
`

const SocialMediaIcon = styled.a`
  margin-right: ${props => props.theme.margins.size03};
  cursor: pointer;
  
  .icon:hover {
    color: ${props => props.theme.colors.sulu};
  } 
`

const MailLink = styled.a`
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.size02};
  cursor: pointer;
  text-decoration: none;
  
  &:hover {
    color: ${props => props.theme.colors.sulu};
  }
`

const SectionInfoLabel = styled.div`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.size02};
`

const Divider = styled.div`
  margin-bottom: ${props => props.theme.margins.size04};
`

const SmallDivider = styled.div`
  margin-bottom: ${props => props.theme.margins.size02};
`

const MailIconContainer = styled.div`
  margin-right: ${props => props.theme.margins.size02};
`

const PermissionContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: ${props => props.theme.margins.size02};
`

const PermissionText = styled.span`
    line-height: ${props => props.theme.fontSizes.size05};
    font-size: ${props => props.theme.fontSizes.size01};
    color: ${props => props.theme.colors.grey80};
    text-align: left;
    margin-left: ${props => props.theme.margins.size02};
`

const EmailResponseContainer = styled.div`
    margin-top: ${props => props.theme.margins.size01};
`

const EmailSuccessText = styled.span`
    line-height: ${props => props.theme.fontSizes.size05};
    font-size: ${props => props.theme.fontSizes.size02};
    color: ${props => props.theme.colors.sulu};
    text-align: left;
    margin-left: ${props => props.theme.margins.size02};
`

const EmailWarningText = styled.span`
    line-height: ${props => props.theme.fontSizes.size05};
    font-size: ${props => props.theme.fontSizes.size02};
    color: ${props => props.theme.colors.wildWaterLemon};
    text-align: left;
    margin-left: ${props => props.theme.margins.size02};
`

const ExternalLink = styled.div`
  cursor: pointer;
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.size02};
  margin-bottom: ${props => props.theme.margins.size02};

  &:hover {
     color: ${props => props.theme.colors.sulu};
  }
`

const InternalLink = styled(props => <Link {...props} />)`
  cursor: pointer;
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.size02};
  text-decoration: none;
  margin-bottom: ${props => props.theme.margins.size02};

  &:hover {
     color: ${props => props.theme.colors.sulu};
  }
`

class Footer extends PureComponent {
    state = {
        emailPermission: false,
        email: "",
        emailSubscribeSuccess: false,
        emailSubscribeError: false,
    }

    subscribe = () => {
        if (!this.state.emailPermission) {
            return
        }

        this.setState({
            emailSubscribeSuccess: false,
            emailSubscribeError: false,
        })

        sendPost(`${poqqyApiUrl}/onboarding`, { "email": this.state.email })
            .then(() => this.setState({ emailSubscribeSuccess: true }))
            .catch(() => this.setState({ emailSubscribeError: true }))
    }

    navigateToOptimizer = () => {
        ReactGA.event({
            category: "Optimizer",
            action: "User clicked link to optimizer",
        })
        window.open("https://optimizer.poqqy.com", "_self")
    }

    render() {
        return (
            <PoqqyFooter>
                <FooterContentContainer>
                    <Section flex={1}>
                        <div>
                            <SectionHeader>Contact</SectionHeader>
                            <SectionInfoContainer>
                                <MailLink href='mailto:info@poqqy.com'>
                                    <MailIconContainer><FontAwesomeIcon size={"lg"}
                                                                        icon={faEnvelope}/></MailIconContainer>
                                    info@poqqy.com
                                </MailLink>
                                <Divider/>
                                <SectionInfoLabel>Laat hier je email achter, als je up to date wilt
                                    blijven!</SectionInfoLabel>
                                <SmallDivider/>
                                <div>
                                    <ButtonInput value={this.state.email}
                                                 onChange={(event) => this.setState({ email: event.target.value })}
                                                 placeholder='Je email hier' width={"47%"}/>
                                    <InputButton onClick={this.subscribe}>Submit</InputButton>

                                    <PermissionContainer
                                        onClick={() => this.setState({ emailPermission: !this.state.emailPermission })}>
                                        <Checkbox checked={this.state.emailPermission}
                                                  readOnly={true}/>
                                        <PermissionText>Ja, mijn email mag gebruikt worden om mij op de hoogte te houden
                                            van het
                                            platform</PermissionText>
                                    </PermissionContainer>
                                </div>
                                {this.state.emailSubscribeSuccess && (
                                    <EmailResponseContainer>
                                        <FontAwesomeIcon size={"md"} className={"icon"} color={"#AEEA72"}
                                                         icon={faCheck}/>
                                        <EmailSuccessText>Je email is geregistreerd!</EmailSuccessText>
                                    </EmailResponseContainer>
                                )}

                                {this.state.emailSubscribeError && (
                                    <EmailResponseContainer>
                                        <FontAwesomeIcon size={"md"} className={"icon"} color={"#FF6384"}
                                                         icon={faTimes}/>
                                        <EmailWarningText>We kunnen de server niet bereiken</EmailWarningText>
                                    </EmailResponseContainer>
                                )}
                            </SectionInfoContainer>
                        </div>
                    </Section>
                    <Section flex={1}>
                        <div>
                            <SectionHeader>Social</SectionHeader>
                            <SectionInfoContainer>
                                <SocialMediaContainer>
                                    <SocialMediaIcon href='https://www.facebook.com/POQQY-1204646886379346/'
                                                     target='_blank'>
                                        <FontAwesomeIcon size={"lg"} className={"icon"} color={"white"}
                                                         icon={faFacebook}/>
                                    </SocialMediaIcon>
                                    <SocialMediaIcon>
                                        <FontAwesomeIcon size={"lg"} className={"icon"} color={"white"}
                                                         icon={faInstagram}/>
                                    </SocialMediaIcon>
                                    <SocialMediaIcon>
                                        <FontAwesomeIcon size={"lg"} className={"icon"} color={"white"}
                                                         icon={faLinkedin}/>
                                    </SocialMediaIcon>
                                </SocialMediaContainer>
                            </SectionInfoContainer>
                        </div>
                    </Section>

                    <Section isLast={true} flex={1}>
                        <div>
                            <SectionHeader>Links</SectionHeader>
                            <SectionInfoContainer>
                                <InternalLink to={"/"}>Home</InternalLink>
                                <ExternalLink onClick={this.navigateToOptimizer}>Optimizer</ExternalLink>
                                <InternalLink to={"/certificaten"}>CO2 certificaten</InternalLink>
                            </SectionInfoContainer>
                        </div>
                    </Section>
                </FooterContentContainer>
            </PoqqyFooter>
        )
    }
}

export default Footer

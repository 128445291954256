import React from "react"
import styled from "styled-components"

const Input = styled.input`
    width: ${props => props.width};
    max-width: ${props => props.maxWidth ? `${props.maxWidth}` : "100%"};
    border-radius: 26px;
    padding: ${props => props.theme.margins.size02} ${props => props.theme.margins.size03};
    font-size: ${props => props.theme.fontSizes.size02};
    border: 1px solid ${props => props.theme.colors.grey90};
    color: ${props => props.theme.colors.grey50};
    
    &:focus {
      outline: none;
    }
    
    &::placeholder {
      color: ${props => props.theme.colors.grey80};
    }
`

export default Input

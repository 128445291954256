import styled, {css} from "styled-components"
import React from 'react';

const PoqqyCard = styled.div`
    border-radius: 10px;
    background-color: ${props => props.theme.colors.white};
    box-shadow: rgba(62,57,107,0.05) 0px 6px 31px 0px, rgba(62,57,107,0.05) 0px 0px 0;
    padding: ${props => props.padding || props.theme.margins.size05};
    
    ${props => props.justifyContent && css`
        display: flex;
        justify-content: ${props => props.justifyContent};
    `}
`;

const Card = (props) => {
    return (
        <PoqqyCard {...props}>
            {props.children}
        </PoqqyCard>
    )
};

export default Card;
